import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { BASE_ROOT_URL, CONTEXT_PATH } from '../../constant.var';
import { CurrentUser, UserInfo } from '../../model/user.model';
import { Router } from '@angular/router';
import { ProductDetail } from 'src/app/model/products.model';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsService } from 'src/app/services/products.service';
import { ProductPagination } from 'src/app/model/product.pagination';
import { User } from 'src/model/User';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header.component.css']
})
export class HeaderComponent implements OnInit {
  currentUser: CurrentUser;
  userInfo: UserInfo;
  imgUrl: string;
  menuState: boolean;
  subMenuState: boolean;
  pageIndex = 1;
  pageSize = 3;
  activeMenu: string = 'fuInterior';
  selectedCategory: ProductDetail;
  paginationProduct: ProductPagination;
  productDesktopSubMenuShow: boolean = false;
  constructor(private authService: AuthService,
    private router: Router,
    private http: HttpClient,
    private productsService: ProductsService,
    private authenticationService: AuthService
  ) {

  this.authenticationService.currentUSer.subscribe(x=>{
      this.currentUser=x;
      if (this.currentUser) {
        this.http.get(`${BASE_ROOT_URL}${CONTEXT_PATH}/api/user/getCurrentUser`, {
          headers: {
            "Authorization": "Bearer " + this.currentUser.access_token
          }
        }).subscribe((data: any) => {
          console.log(data);
          if (data.error == "invalid_token") {
            localStorage.removeItem('currentUser');
            location.reload();
          } else {
            this.userInfo = data;
          }
          
        });
      }
    });
  }
  ngOnInit() {
    // this.currentUser = this.authService.currentUserValue;
    // if (this.currentUser) {
    //   this.http.get(`${BASE_ROOT_URL}${CONTEXT_PATH}/api/user/getCurrentUser`, {
    //     headers: {
    //       "Authorization": "Bearer " + this.currentUser.access_token
    //     }
    //   }).subscribe((data: any) => {
    //     console.log(data);
    //     if (data.error == "invalid_token") {
    //       localStorage.removeItem('currentUser');
    //       location.reload();
    //     } else {
    //       this.userInfo = data;
    //     }
        
    //   });
    // }
    this.menuState = false;
    this.subMenuState = false;
  }
  actionLoginOrLogout() {
    if (this.currentUser) {
      this.authService.logout();
    }
    this.router.navigate(['/auth/login']);
  }
  logout() {
    this.authService.logout();
  }
  showListOder() {
    this.router.navigate(['/order']);
  }
  showListProduct(){
    this.router.navigate(['/product']);
  }
  changePage(page, event) {
    event.preventDefault();
    //  event.stopPropagation();
    event.cancelBubble = true;
    if (this.pageIndex != page) {
      this.pageIndex = page;
    }

  }


  toggleMenu() {
    if (this.menuState) {
      this.menuState = false;
    } else {
      this.menuState = true;
    }
  }

  changeMenu(menuName: string) {
    this.activeMenu = menuName;
    console.log(menuName)
    this.toggleMenu();
  }
}
