import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { Subscription } from 'rxjs';
import { FacebookService, InitParams } from 'ngx-facebook';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  pageViewCount = 0;
  subscription: Subscription;
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.subscription = this.appService.visitorCount$.subscribe((newPv) => {
      this.pageViewCount = newPv;
    });
    this.initFacebookService();
  }
  constructor(private appService: ApplicationService, private facebookService: FacebookService) {

  }
    private initFacebookService(): void {
      const initParams: InitParams = { xfbml:true, version:'v3.2'};
      this.facebookService.init(initParams);
    }
}
