import { Injectable } from '@angular/core';
import { CurrentUser, UserInfo } from '../model/user.model';
import { BASE_ROOT_URL,CONTEXT_PATH } from '../constant.var';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<CurrentUser>;
  public currentUSer: Observable<CurrentUser>;

  constructor(private http: HttpClient,
    private router : Router) {
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUSer = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): CurrentUser {
    return this.currentUserSubject.value;
  }

  signUp(userInfo: UserInfo) {
    return this.http.post(`${BASE_ROOT_URL}/crm/register/crmuser/createuser`, userInfo);
  }

  login(username: string, password: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let requestBody = "grant_type=password&username="+username+"&password="+password+"&client_id=spring-security-oauth2-read-write-client&client_secret=spring-security-oauth2-read-write-client-password1234";
    let url = BASE_ROOT_URL+CONTEXT_PATH+`/oauth/token`;
    return this.http.post(
      url,
      requestBody,
      {headers: headers}
      ).pipe(map((user: CurrentUser) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
    }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/auth/login']);
  }
  
}
