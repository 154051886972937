import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ApplicationService } from 'src/app/services/application.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'fu-web';
  now = new Date();
  constructor(private authService: AuthService,private appService:ApplicationService) {
  }

  ngOnInit(): void {
    this.checkExpiredUserToken();
    this.appService.incrementPageCount().subscribe(response=>{
      this.appService.setViewPageCount(response);
    })
  }

  checkExpiredUserToken() {
    if (this.authService.currentUserValue && this.authService.currentUserValue.expired <= this.now.getTime()) {
      this.authService.logout();
    }
  }
}
