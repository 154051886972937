import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})

export class ArticleComponent implements OnInit {

  constructor(private elem: ElementRef) { }
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  ngOnInit() {
  }
  viewDetail(element) {
    let elements = this.elem.nativeElement.querySelectorAll('.detail');
    var count = elements.length;
    for(var i=0;i<count;i++){
      elements[i].classList.remove('detail');
      elements[i].querySelectorAll('video')[0].pause();
    }
    element.parentElement.querySelectorAll('video')[0].play();
    element.parentElement.classList.add('detail');
    element.scrollIntoView({behavior:"smooth"});
  }

  closeDetail(element) {
    element.parentElement.querySelectorAll('video')[0].pause();
    element.parentElement.parentElement.classList.remove('detail');
  }
}
