import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_ROOT_URL } from '../constant.var';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  getCategories(includeEcommerce:boolean=false) {
    return this.http.get(`${BASE_ROOT_URL}/crm/categories?includeEcommerce=${includeEcommerce}`);
  }

  searchCategories(search) {
    return this.http.get(`${BASE_ROOT_URL}/crm/categories/search`, {params:search});
  }

  getListType() {
    return this.http.get(`${BASE_ROOT_URL}/crm/categories/getListTypeCategory`);
  }
}
