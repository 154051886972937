import { Component, Input, OnInit } from '@angular/core';
import * as PhotoSphereViewer from 'photo-sphere-viewer';
import { ProductAttachment, ProductDetail } from '../../model/products.model';
import { ActivatedRoute, Params } from '@angular/router';
import { ProductsService } from '../../services/products.service';
import { BASE_ROOT_URL_IMAGE } from 'src/app/constant.var';

@Component({
  selector: 'app-view-full-image',
  templateUrl: './view-full-image.component.html',
  styleUrls: ['./view-full-image.component.scss']
})
export class ViewFullImageComponent implements OnInit {
  PSV: any;
  productId: string;
  orderResultId: string;
  productDetail: ProductDetail;
  indexImage = 0;
  isImage360 = true;
  imageUrlMobile: string;
  imageUrlDesktop: string;
  imageCaption: string;
  loading = false;
  listAttachments: ProductAttachment[];
  listAttachmentsMobile: ProductAttachment[];
  listAttachmentsDesktop: ProductAttachment[];
  constructor(private route: ActivatedRoute,
    private productsService: ProductsService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => this.productId = params['productId']);
    if (this.productId != null || this.productId != '') {
      this.productsService.getProduct(this.productId)
        .subscribe((data: ProductDetail) => {
          this.productDetail = data;
          this.listAttachments = data.productAttachments;
          this.listAttachmentsMobile = data.productAttachments.filter(x => x.fileType === 'mobileimage' || x.fileType === 'mainmobileimage');
          this.listAttachmentsMobile.sort((a, b) => a.name.localeCompare(b.name));
          this.listAttachmentsDesktop = data.productAttachments.filter(x => x.fileType === 'image' || x.fileType === 'mainimage');
          this.listAttachmentsDesktop.sort((a, b) => a.name.localeCompare(b.name));
          if (data.image360) {
            this.listAttachments.unshift(data.image360);
            this.listAttachmentsMobile.unshift(data.image360);
            this.listAttachmentsDesktop.unshift(data.image360);
          }
          this.initViewer();
        });

    } else {
      this.route.params.subscribe((params: Params) => this.orderResultId = params['orderResultId']);
      this.productsService.getProduct(this.productId)
        .subscribe((data: ProductDetail) => {
          this.productDetail = data;
          this.listAttachmentsMobile = data.productAttachments.filter(x => x.fileType === 'mobileimage' || x.fileType === 'mainmobileimage');
          this.listAttachmentsDesktop = data.productAttachments.filter(x => x.fileType === 'image' || x.fileType === 'mainimage');
          if (data.image360) {
            this.listAttachments.unshift(data.image360);
            this.listAttachmentsMobile.unshift(data.image360);
            this.listAttachmentsDesktop.unshift(data.image360);
          }
          this.initViewer();
        });
    }



  }

  initViewer() {
    if(this.productDetail.image360) {
      this.isImage360 = true
      this.PSV = new PhotoSphereViewer(
        {
          container: 'photosphere',
          panorama: this.productDetail.image360 ? this.productDetail.image360.urlMaximumSize : '',
          caption: this.productDetail.image360 ? `${this.productDetail.image360.description}` : `${this.productDetail.productAttachments[0].description}`,
          loading_text: 'Loading...',
          anim_speed: '-2rpm',
          move_speed: 1.1,
          max_fov:120,
          min_fov:30,
          default_fov:120,
          time_anim: false,
          navbar: [
            'caption',
          ]
        });
    } else {
      this.isImage360 = false;
      this.imageUrlMobile = this.listAttachmentsMobile[0].urlMaximumSize;
      this.imageUrlDesktop = this.listAttachmentsDesktop[0].urlMaximumSize;
    }

  }

  updateImage(screen?: String) {
    let attachments : ProductAttachment[] = this.listAttachmentsDesktop;
    console.log(screen);
    if (screen === 'mobile') {
      attachments = this.listAttachmentsMobile;
     
    }
    if (attachments[this.indexImage].fileType == 'image360') {
      this.PSV.setPanorama(this.generateLink(attachments[this.indexImage].urlMaximumSize), {
        longitude: 3.715,
        latitude: 0.574,
        zoom: 50
      }, true)
        .then(() => {
          this.PSV.setCaption(`${attachments[this.indexImage].name}: ${attachments[this.indexImage].description}`);
          this.loading = false;
        });
      this.isImage360 = true;
    } else {
      this.isImage360 = false;
      this.imageCaption = attachments[this.indexImage].name + ':' + attachments[this.indexImage].description;
      this.imageUrlMobile = attachments[this.indexImage].urlMaximumSize;
      this.imageUrlDesktop = attachments[this.indexImage].urlMaximumSize;
      this.loading = false;

    }

  }
  nextImage(screen: String) {
    if (this.loading) {
      return;
    }
    let length : number = this.listAttachmentsDesktop.length;
    if (screen === 'mobile') {
      length = this.listAttachmentsMobile.length;
    }
    if (this.indexImage < length - 1) {
      this.indexImage += 1;
    } else {
      this.indexImage = 0;
    }
    this.loading = true;
    this.updateImage(screen);
  }

  prevImage(screen: String) {
    if (this.loading) {
      return;
    }
    let length : number = this.listAttachmentsDesktop.length;
    if (screen === 'mobile') {
      length = this.listAttachmentsMobile.length;
    }
    if (this.indexImage > 0) {
      this.indexImage -= 1;
    } else {
      this.indexImage = length - 1;
    }
    this.loading = true;
    this.updateImage(screen);
  }

  chooseImage(index: number, screen: String) {
    if (this.loading) {
      return;
    }
    this.indexImage = index;
    this.loading = true;
    this.updateImage(screen);
  }
  generateLink(url : String) {
    return BASE_ROOT_URL_IMAGE + url;
  }
}
