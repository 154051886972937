import { Component, OnInit, Input,OnChanges,SimpleChanges } from '@angular/core';
import { ProductDetail, ProductAttachment } from '../../model/products.model';
import { ProductsService } from '../../services/products.service';
import { CategoryService } from 'src/app/services/category.service';
import { Router } from '@angular/router';
import { BASE_ROOT_URL_IMAGE } from 'src/app/constant.var';
@Component({
    selector: 'app-slider',
    templateUrl: 'slider.component.html',
    styleUrls:['slider.component.scss']
})

export class SliderComponent implements OnInit,OnChanges {
    backLink = '<li class="nav-item"><a class="nav-link nav-back-link" href="javascript:;">Back</a></li>';
    productTemplateCategory: String = '704e01d7-6f4c-1321-816f-56cdda31000c';
    baseRootUrlImage = BASE_ROOT_URL_IMAGE;
    currentImageDetail: ProductDetail;
    listCategories: ProductDetail[];
    isOpenProductMenuMobile = false;
    oldProdId: string;
    oldIndexThumbnail: number;
    currentIndexImage = 0;
    loading = false;
    PSV: any;
    show360 = false;
    isNormalPicture=true;
    statusPicture:String='';
    listProducts: ProductDetail[] = [];
    previewImgUrl: string;
    productDesktopSubMenuState: boolean;
    pageIndex = 1;
    pageSize = 3;
    activeMenu:string = 'fuInterior';
    selectedCategory: ProductDetail;
    productDesktopSubMenuShow: boolean = false;
    constructor(
      private productsService: ProductsService, 
      private router: Router,
      private categoryService: CategoryService,
      ) { 
        this.productsService.getProductsByCat(this.productTemplateCategory)
        .subscribe((data: ProductDetail[]) => {
          this.listProducts = data;
        });
        this.categoryService.getCategories()
        .subscribe((data: any) => {
          this.listCategories = data;
        });
      }

    ngOnInit() { 
       
    }
    ngOnChanges(changes: SimpleChanges): void {
        if(changes.data.currentValue){
            this.currentImageDetail=changes.data.currentValue;
        }
    }
    getMainImgSrc(productAttachments : ProductAttachment[], screen: string) {
      if(productAttachments)
      for (var i=0; i<productAttachments.length; i++) {
        if ((productAttachments[i].fileType == 'mainimage' && screen == 'desktop')||(productAttachments[i].fileType  == 'mainmobileimage' && screen == 'mobile')) {
          return productAttachments[i].urlNormalSize;
        }
      }
    }
      
  isShowMobileImage(productAttachment : ProductAttachment, index: number) {
    if (productAttachment.fileType == 'mainmobileimage' && index != this.oldIndexThumbnail) {
      this.oldIndexThumbnail = index;
      return true;
    }
    return false;
  }
  chooseImage(index: number) {
    if (this.show360 &&this.isNormalPicture==false) {
      if (this.loading) {
        return;

      }
      this.loading = true;
    }
    this.currentIndexImage = index;
    this.loading = true;
  }
  showView360(event){
    if(this.isNormalPicture){
      if(this.listProducts[this.currentIndexImage].image360){
        this.PSV.showNavbar();
        this.isNormalPicture=false;
        this.statusPicture="View normal";
      }
    }else{
      this.PSV.hideNavbar();
      this.show360=false;
      this.isNormalPicture=true;
      this.statusPicture="View 360";
    }
  }
  nextImage() {
    if (this.show360&&this.isNormalPicture==false) {
      if (this.loading) {
        return;

      }
      this.loading = true;
    }
    if (this.currentIndexImage < this.listProducts.length - 1) {
      this.currentIndexImage += 1;
    } else {
      this.currentIndexImage = 0;
    }
  }

  prevImage() {
    if (this.show360&&this.isNormalPicture==false) {
      if (this.loading) {
        return;

      }
      this.loading = true;
    }
    if (this.currentIndexImage > 0) {
      this.currentIndexImage -= 1;
    } else {
      this.currentIndexImage = this.listProducts.length - 1;
    }
  }
  openFullViewImage(product: ProductDetail) {
    this.router.navigate(['/view', product.id]);
  }
  mouseEnter(product: ProductDetail) {
    console.log(product);
    if (product == null) {
        product = this.selectedCategory.products[0];
    }
    
    product.productAttachments.forEach((item, index) => {
      if (item.fileType == 'mainmobileimage') {
        this.previewImgUrl = item.urlNormalSize;
      }
    });
  }
  showSubMenu() {
    this.productDesktopSubMenuShow = true;
  }
  hideSubMenu() {
    this.productDesktopSubMenuShow = false;
  }
  toggleSubProduct(category, event) {
    event.preventDefault();
    event.cancelBubble = true;
    if (category.products == undefined || category.products.length == 0) {
      this.productDesktopSubMenuShow=false;
      return;
    }
    if (category.id === this.selectedCategory) {
      this.productDesktopSubMenuShow = !this.productDesktopSubMenuShow;
    } else {
      this.selectedCategory = category;
      let product = this.selectedCategory.products[0];
      product.productAttachments.forEach((item, index) => {
        if (item.fileType == 'mainmobileimage') {
          this.previewImgUrl = item.urlNormalSize;
        }
      });
      this.productDesktopSubMenuShow = true;
    }
  }
  closeSubMenu() {
    setTimeout(() => {
      this.productDesktopSubMenuState = false;
    }, 0);
    this.productDesktopSubMenuShow = false;
  }
  toggleDesktopSubMenu(menuName: string) {
    this.activeMenu = menuName;
    if (this.productDesktopSubMenuState) {
      setTimeout(() => {
        this.productDesktopSubMenuState = false;
      }, 0);
      this.productDesktopSubMenuShow = false;
    } else {
      this.productDesktopSubMenuState = true;
    }
    // }
  }
  showProductMenuMobile(element) {
    element.parentElement.classList.add('active');
  }
  removeProductMenuMobile(element) {
    element.parentElement.parentElement.parentElement.classList.remove('active');
  }

  generateLink(url : String) {
    return BASE_ROOT_URL_IMAGE + url;
  }
}