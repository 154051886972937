import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductAttachment, ProductDetail } from '../model/products.model';
import { BASE_ROOT_URL } from '..//constant.var';
import { ProductPagination } from 'src/app/model/product.pagination';
import { CommonUtils } from '../shared/services/common-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  toggleStatus(id: string) {
    return this.http.get(`${BASE_ROOT_URL}/crm/products/toggleStatus/${id}`);
  }
  updateProduct(id:string,product:object) {
  //   var httpOptions = {
  //     headers: new HttpHeaders({
  //         'Content-Type': 'text/html',
  //     })
  // };
    return this.http.post(`${BASE_ROOT_URL}/crm/products/${id}`,product);
  }
  currentImage: ProductAttachment;
  currentProduct: ProductDetail;

  constructor(private http: HttpClient) { }

  getProducts() {
    return this.http.get(`${BASE_ROOT_URL}/crm/products/search/top10`);
  }
  search(search) {
    const buildParams = CommonUtils.buildParams(search);
    return this.http.get(`${BASE_ROOT_URL}/crm/products/search`, {params: buildParams});
  }
  getProductsByCat(categoryId : String) {
    return this.http.get(`${BASE_ROOT_URL}/crm/products/search/top10/${categoryId}`);
  }

  getProduct(productId: string) {
    return this.http.get(`${BASE_ROOT_URL}/crm/products/${productId}`);
  }
  getPaginationProduct(pageIndex: number, pageSize: number){
    return this.http.get<ProductPagination>(`${BASE_ROOT_URL}/crm/products/getProduct?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  }
  getAllProduct(pageIndex:number,pageSize:number){
    return this.http.get<ProductPagination>(`${BASE_ROOT_URL}/crm/products/getAllProduct?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  }
}
