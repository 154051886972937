export class UserAccount {
  username: string;
  otp:string;
  password: string;
  confirmPassword:string;
  fullName:string;
  roles:string[];

  constructor(){
    this.roles=["user"];
  }
}
