import {Component, OnInit} from '@angular/core';
import {UserService} from "../user.service";
import {HttpHeaders} from "@angular/common/http";
import {User} from "../../model/User";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit {
  user: User = {
    isChangePassword:false,
    gender:'',
    birthDate:null,
    fullName:'',
    email:"",
    confirmPassword:'',
    password:'',
    username:''
  };
  updateUserInfoFormGroup: FormGroup;


  constructor(private userServie: UserService,private fb:FormBuilder, private router:Router) {
    this.updateUserInfoFormGroup = this.fb.group({
      username:[''],
      fullName:['',[Validators.required, Validators.minLength(7)]],
      email:[''],
      birthDate:[''],
      gender:[''],
      password:[''],
      confirmPassword:['']
    })
  }

  // Use Token
  onSubmitUpdateUserInfoFormGroup() {
    if (this.updateUserInfoFormGroup.valid) {
      const {value} = this.updateUserInfoFormGroup;
      this.user = value;
      console.log(this.user);
      if (this.user.password != null) {
        this.user.isChangePassword= true;
      } else {
        this.user.isChangePassword= false
      }
      console.log(this.updateUserInfoFormGroup);
      const header = new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('JWT_TOKEN')}`,
      });
      this.userServie.updateUserInformation(header,this.user).subscribe(
        (response) => {
          console.log(response);
          if (response.code == 1) {
            alert(response.message);
            localStorage.removeItem('JWT_TOKEN');
            this.router.navigate(['/']);
          } else {
            alert(response.message);
          }
        },(error) => {
          alert("Update error");
        }
      )
    }
  }
  ngOnInit() {
    this.getUserInf();
  }


  // onSubmitUpdateUserInfoFormGroup() {
  //   if (this.updateUserInfoFormGroup.valid) {
  //     const {value} = this.updateUserInfoFormGroup;
  //     this.user = value;
  //     if (this.user.password == null) {
  //       this.user.isChangePassword = false;
  //     } else {
  //       this.user.isChangePassword = true
  //     }
  //     var date = new Date(this.user.birthDate);
  //     this.user.birthDate = date.getTime() / 10000;
  //     console.log(this.user);
  //     this.userServie.updateUser(this.user).subscribe(
  //       (response) => {
  //         alert(response.message);
  //         this.router.navigate(["/login"])
  //       },error => alert("Update fail!")
  //     )
  //   }
  // }

  //Use Token
  getUserInf() {
    const header = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('JWT_TOKEN')}`,
    });

    this.userServie.getUserInformation(header).subscribe(
      (respone) => {
        this.user = respone;
        console.log(this.user);
        console.log(this.updateUserInfoFormGroup);
      },(error1) => {
        this.router.navigate(['/login']);
      }
    )
  }


  //simple
  // getUserInf() {
  //   this.userServie.getUserInforSimple(this.userServie.user).subscribe(
  //     (response) => {
  //       console.log("Response: "+ response);
  //       this.user = response;
  //       console.log(this.user);
  //     }
  //   )
  // }

}
