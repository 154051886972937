import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_ROOT_URL } from '../constant.var';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private visitorCountValue = 0;
  private visitorCount: BehaviorSubject<number> = new BehaviorSubject<number>(this.visitorCountValue);

  visitorCount$: Observable<number> = this.visitorCount.asObservable();
  constructor(private http: HttpClient) {
  }

  incrementPageCount(): Observable<number> {
    return this.http.get<number>(`${BASE_ROOT_URL}/crm/application/pageView`);
  }

  setViewPageCount(v: number) {
    this.visitorCount.next(v);
    this.visitorCountValue = v;
  }
}
