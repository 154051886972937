// export const BASE_ROOT_URL = 'https://futgroup.vn:8443';
export const BASE_ROOT_URL = 'https://api.futgroup.vn';
export const BASE_ROOT_URL_IMAGE = 'https://api.futgroup.vn';
// export const URL_DATA_API=`https://futgroup.vn:8443/crm`;
export const URL_DATA_API=`https://api.futgroup.vn/crm`;
export const CONTEXT_PATH=`/crm`;
export const firebaseConfig = {
  apiKey: 'AIzaSyBTe5wF_b0NMffBOeNIv5yjV8tbyS7cMek',
  authDomain: 'fu-web.firebaseapp.com',
  databaseURL: 'https://fu-web.firebaseio.com',
  projectId: 'fu-web',
  storageBucket: 'fu-web.appspot.com',
  messagingSenderId: '351825122868',
  appID: '1:351825122868:web:ee647ebaf30cacf2ab5c61',
};
export const defaultx = 'menu';