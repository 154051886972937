import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "play-button",
  templateUrl: "./play-button.component.html",
  styleUrls: ["./play-button.component.scss"],
})
export class PlayButtonComponent implements OnInit {
  @Input() videoUrl: String;
  constructor() { }

  ngOnInit() { }
  playVideo() {
    if (this.videoUrl != null) {
      window.open(this.videoUrl.toString(), "_blank");
    }
  }
}
