import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {CodeJson} from "../model/CodeJson";
import {catchError, map, tap} from "rxjs/operators";
import {UserAccount} from "../model/UserAccount";
import {Token} from "../model/Token";
import {User} from "../model/User";
import {Router} from "@angular/router";
import {JwtResponse} from '../model/response/JwtResponse';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: UserAccount = new UserAccount();
  constructor(private http: HttpClient,private router:Router) {
  }



  checkPhoneNumber(number: string): Observable<CodeJson> {
    return this.http.get<CodeJson>(`http://localhost:8081/crm/register/crmuser/checkphonenumber/${number}`);
  }

  // createAccount(acount: Partial<UserAccount>): Observable<CodeJson> {
  //   return this.http.post<CodeJson>("http://localhost:8081/crm/register/crmuser/createuser", acount);
  // }
  createAccountSecurity(acount: Partial<UserAccount>): Observable<CodeJson> {
      return this.http.post<CodeJson>("http://localhost:8081/crm/register/crmuser/createuser", acount);
    }

  getUserInformation(headers:HttpHeaders):Observable<User> {
    return this.http.get<User>("http://localhost:8081/crm/api/crmuser/getcurrentuser",{headers});
  }
  updateUserInformation(headers: HttpHeaders, value:User ): Observable<CodeJson> {
      return this.http.post<CodeJson>("http://localhost:8081/crm/api/crmuser/updatemobileuser",value,{headers});
  }
  updatePassword(user:Partial<UserAccount>):Observable<CodeJson> {
    return this.http.post<CodeJson>("http://localhost:8081/crm/api/crmuser/updatePassword",user);
  }



  loginToServer(user: Partial<User>):Observable<JwtResponse> {
    return this.http.post<JwtResponse>("http://localhost:8081/crm/oauth/token",user);
  }
  // getUserInforSimple(account:UserAccount):Observable<User> {
  //   return this.http.post<User>("http://localhost:8081/crm/api/crmuser/getcurrentuser",account);
  // }
  updateUser(user: Partial<User>):Observable<CodeJson> {
    return this.http.post<CodeJson>("http://localhost:8081/crm/api/crmuser/updatemobileuser",user);
  }

}
