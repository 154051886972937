import { JwtInterceptor } from './helpers/jwt.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ViewFullImageComponent } from './components/view-full-image/view-full-image.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { SwiperModule, SwiperConfigInterface,
  SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { UserUpdateComponent } from './user-update/user-update.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { HomeModule } from './pages/home/home.module';
// import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
// import {NgbModule,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};
//export class MaterialModule {}
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    PinchZoomModule,
    HttpClientModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HomeModule
    // NgbModule
    
    // TradeModule,
    // ArTechnologyModule,
 
  ],
  declarations: [
    AppComponent,
    ViewFullImageComponent,
    ThumbnailComponent,
    UserUpdateComponent,
    ForgotPasswordComponent,
    // ConfirmDeleteComponent
  ],
//  entryComponents:[ConfirmDeleteComponent],
  providers: [
    // ConfirmDeleteComponent,
    // NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:ErrorInterceptor,
      multi:true
    }

  ],
  exports: [
       
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
