import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { BodyComponent } from './body/body.component';
import { HomeComponent } from './home.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { PolicyComponent } from '../policy/policy.component';
import { ArticleComponent } from '../article/article.component';
import { SliderComponent } from '../../components/slider/slider.component';
import { FacebookModule } from 'ngx-facebook';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    HomeRoutingModule,
    FacebookModule.forRoot(),
    SharedModule
    ],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
  declarations: [BodyComponent, HomeComponent, FooterComponent, HeaderComponent,PolicyComponent,SliderComponent, ArticleComponent]
})
export class HomeModule { }
