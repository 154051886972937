import { Component, OnInit } from '@angular/core';
import  {defaultx } from '../../../constant.var';
import { Router } from '@angular/router';
@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {

  activeMenu: string = 'fuInterior';
  constructor(
    private router: Router,) { }

  ngOnInit() {
    this.activeMenu = defaultx; 
  }


}
