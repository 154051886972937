import {Component, OnInit} from '@angular/core';
import {CodeJson} from '../../model/CodeJson';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../user.service';
import {Router} from '@angular/router';
import {UserAccount} from '../../model/UserAccount';
import {WindowService} from '../window.service';
import {PhoneNumber} from '../../model/PhoneNumber';
import {environment} from '../../environments/environment';
import * as firebase from 'firebase';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  codeJson: CodeJson;
  number: string;
  checkPhoneNumber: boolean = false;
  phoneNumberFormGroup: FormGroup;
  otpFormGroup: FormGroup;
  changePasswordFormGroup: FormGroup;
  showFormNumber: boolean = true;
  showFormOtp: boolean = false;
  showFormChangePassword: boolean = false;


  windowRef:any;
  phoneNumber = new PhoneNumber();
  user: any;
  verificationCode: string;
  constructor(private userService: UserService, private router: Router, private fb: FormBuilder,private win: WindowService) {
  }

  ngOnInit() {
    this.phoneNumberFormGroup = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.pattern('^0[0-9]{9}$')]],
    });
    this.otpFormGroup = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.changePasswordFormGroup = this.fb.group({
      password:['', [Validators.required, Validators.minLength(6)]],
      confirmPassword:['']
    });


    this.windowRef = this.win.windowRef;
    if (!firebase.apps.length) {
    }
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    this.windowRef.recaptchaVerifier.render()
  }

  onCheckPhoneNumberForm() {
    if (this.phoneNumberFormGroup.valid) {
      this.userService.checkPhoneNumber(this.phoneNumberFormGroup.get("phoneNumber").value).subscribe(
        (response) => {
          if (response.code == 1)  {
            this.showFormNumber = false;
            this.showFormOtp = true;
            this.number = this.phoneNumberFormGroup.get("phoneNumber").value;
            this.sendLoginCode('84',this.number);
          }else {
            alert("Phone number is not found!");
          }
        }, error => console.log(error)
      )
    }
  }
  onCheckOtp() {
    this.verifyLoginCode(this.otpFormGroup.get("otp").value);
  }

  onChangePassword() {
    if (this.changePasswordFormGroup.valid) {
      const account : UserAccount = new UserAccount();
      account.username = this.number;
      account.password = this.changePasswordFormGroup.get("password").value;
      this.userService.updatePassword(account).subscribe(
        (response)=> {
          alert(response.message);
          this.router.navigate(["/"]);
        },error => console.log(error)
      )
    }

  }


  sendLoginCode(country:string,number:string) {
    this.phoneNumber.number= number;
    this.phoneNumber.country= country;
    const appVerifier = this.windowRef.recaptchaVerifier;
    console.log(this.phoneNumber.e164);
    firebase.auth().signInWithPhoneNumber(this.phoneNumber.e164, appVerifier)
      .then(result => {

        console.log(result);
        this.windowRef.confirmationResult = result;
      })
      .catch( error => console.log(error) );

  }
  verifyLoginCode(verificationCode:string) {
    this.windowRef.confirmationResult
      .confirm(verificationCode)
      .then( result => {
        this.verificationCode = verificationCode;
        this.showFormOtp = false;
        this.showFormChangePassword = true;
      })
      .catch( error => {
        alert("Invalid verification code");
        console.log(error, "ERROR Invalid code")
      })
    ;
  }
}
