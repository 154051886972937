import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ViewFullImageComponent } from './components/view-full-image/view-full-image.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomeModule'
  },
  {
    path: 'customer',
    loadChildren: './pages/customer/customer.module#CustomerModule'
  },
  {
    path: 'admin',
    loadChildren: './pages/admin/admin.module#AdminModule'
  },
  {
    path: 'auth',
    loadChildren: './pages/auth/auth.module#AuthModule'
  },
  {
    path: 'view/:productId',
    component: ViewFullImageComponent
  },
  {
    path: 'order-result/:orderResultId',
    component: ViewFullImageComponent
  },
  {
    path: 'ar-tech',
    loadChildren: './pages/ar-technology/ar.module#ArTechnologyModule'
  },
  {
    path: '',
    loadChildren: './pages/trade/trade.module#TradeModule'
  },
  {
    path: 'order',
    loadChildren: './pages/orders/orders.module#OrdersModule'
  },
  {
    path:'product',
    loadChildren:'./pages/products/products.module#ProductsModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
